<div class="sidebar-wrapper">
  <div class="profile-container">
    <img class="profile" src="assets/images/abdo.jpg" alt="Abdo Hamoud">
    <h1 class="name">{{myName}}</h1>
    <h3 class="tagline">{{myJob}}</h3>
  </div><!--//profile-container-->
  <div class="contact-container container-block">
    <ul class="list-unstyled contact-list">
      <li class="email">
        <i class="fas fa-envelope"></i>
        <a href="mailto:{{contactDetails.email}}">{{contactDetails.email}}</a>
      </li>
      <li class="phone">
        <i class="fas fa-phone"></i>
        <a href="tel:{{contactDetails.phone}}">{{contactDetails.phone}}</a></li>
      <li class="behance">
        <i class="fab fa-behance"></i>
        <a href="{{contactDetails.behance}}" target="_blank">Behance</a>
      </li>
      <li class="github">
        <i class="fab fa-github"></i>
        <a href="{{contactDetails.github}}" target="_blank">Github</a>
      </li>
      <li class="github">
        <i class="fab fa-stack-overflow"></i>
        <a href="{{contactDetails.stackoverflow}}" target="_blank">Stack Overflow</a>
      </li>
      <li class="linkedin">
        <i class="fab fa-linkedin-in"></i>
        <a href="{{contactDetails.linkedin}}" target="_blank">Linkedin</a>
      </li>
      <li class="twitter">
        <i class="fab fa-twitter"></i>
        <a href="{{contactDetails.twitter}}" target="_blank">Twitter</a>
      </li>
    </ul>
  </div><!--//contact-container-->
  <div class="education-container container-block">
    <h2 class="container-block-title">Education</h2>
    <div class="item">
      <h4 class="degree">Information Systems</h4>
      <h5 class="meta">Delta Academy</h5>
      <div class="time">2008 – 2014</div>
    </div><!--//item-->
  </div><!--//education-container-->
  <div class="education-container container-block">
    <h2 class="container-block-title">Awards</h2>
    <div class="item">
      <h4 class="degree">Egyptian Ministry
        Communications and Information Technology</h4>
      <h5 class="meta">Tamkeen competition</h5>
      <p>Build web app and mobile app to help blind peoples.</p>
      <div class="time">Nov 2016</div>
    </div><!--//item-->
  </div><!--//education-container-->
  <div class="languages-container container-block">
    <h2 class="container-block-title">Languages</h2>
    <ul class="list-unstyled interests-list">
      <li>Arabic <span class="lang-desc">(Native)</span></li>
      <li>English <span class="lang-desc">(Advanced)</span></li>
    </ul>
  </div><!--//interests-->
  <div class="interests-container container-block">
    <h2 class="container-block-title">Technical Skills</h2>
    <ul class="list-unstyled interests-list">
      <li>Adobe Photoshop</li>
      <li>Adobe XD</li>
      <li>HTML, HTML5</li>
      <li>CSS ( LESS , SCSS )</li>
      <li>Javascript</li>
      <li>TypeScript</li>
      <li>jQuery</li>
      <li>Angular JS</li>
      <li>Angular</li>
      <li>React</li>
      <li>Vue.js</li>
      <li>Node.js</li>
      <li>Kendo UI</li>
      <li>Ionic</li>
      <li>Cordova</li>
      <li>Flutter</li>
      <li>PHP</li>
      <li>Laravel</li>
      <li>WordPress</li>
      <li>MySQL</li>
      <li>MongoDB</li>
      <li>Frappe ( ERPNext )</li>
    </ul>
  </div><!--//interests-->
</div><!--//sidebar-wrapper-->
