import {Component, OnInit} from '@angular/core';
import {BehanceService} from '../../services/behance.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  experiences: any[];
  portfolio: any;
  p = 1;

  constructor(private behance: BehanceService) {
    this.experiences = [
      {
        title: 'Founder',
        company: 'Tatwerat',
        fromDate: {
          year: '2009',
          month: 'Jun'
        },
        toDate: 'Present',
        // tslint:disable-next-line:max-line-length
        description: 'Started as a web designer, UI developer and windows applications developer with VB6 and right now we working with new technologies and framework like WordPress using php , javascript libraries (jQuery, Angular, Node.JS) and mobile cross platforms.'
      },
      {
        title: 'UI/UX Designer and Frontend Developer',
        company: 'Tawrny',
        fromDate: {
          year: '2013',
          month: 'Sep'
        },
        toDate: 'Jun 2015',
        // tslint:disable-next-line:max-line-length
        description: 'Work as UI/UX designer and frontend developer using WordPress development to create websites and build mobile applications design.'
      },
      {
        title: 'UI/UX Designer and Frontend Developer',
        company: 'Naba Software',
        fromDate: {
          year: '2015',
          month: 'Nov'
        },
        toDate: 'May 2018',
        // tslint:disable-next-line:max-line-length
        description: 'Work as UI/UX consultant for windows applications and web , mobile applications designer plus WordPress and php developer.'
      },
      {
        title: 'Full Stack Developer and UI/UX Team Leader',
        company: 'Effective Value Solutions',
        fromDate: {
          year: '2018',
          month: 'Sep'
        },
        toDate: 'Present',
        // tslint:disable-next-line:max-line-length
        description: 'We\'re working to build fully ERP system on Python Frappé framework using ERPNext application, I manage user interface and user experience using PHP and Vue to develop frontend use frameworks like Laravel and integration with python API.'
      }
    ];
    this.get_portfolio();
  }

  get_portfolio() {
    this.behance.getPortfolio().subscribe((response) => {
      this.portfolio = (response) ? response['projects'] : [];
    });
  }

  ngOnInit(): void {
  }

}
